<template>
  <el-card class="box-card">
    <el-form style="margin-top: 30px" ref="form" label-width="130px" :model="form" :rules="rules">
      <el-form-item style="margin-bottom: 20px" label="活动标题" prop="name">
        <el-input type="text" v-model="form.name" style="width: 25%" size="small" placeholder="请输入活动标题"/>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="活动时间">
        <el-tooltip placement="top">
          <div slot="content">定金支付时间</div>
          <el-date-picker
              v-model="query_time"
              size="small"
              type="datetimerange"
              @change="getTimeSection"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd HH:mm:ss"
              align="right"
          />
        </el-tooltip>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="封面图" prop="mask">
        <el-button v-if="!form.mask" @click="showSelect = true">选择上传图片</el-button>
        <img
            v-else
            class="avatar"
            :src="form.mask"
            style="display: block; height: 100px"
        />
        <fileSelect :visible.sync="showSelect" append-to-body state="image" title="标题"
                    @confirm="confirmHandle"></fileSelect>
        <!--        <div>(建议上传图片尺寸：80px*80px)</div>-->
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="宣传语" prop="slogan">
        <el-input
            type="textarea"
            :autosize="{ minRows: 2}"
            v-model="form.slogan"
            size="small"
            style="width: 25%"
            placeholder="请输入宣传语"
        />
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="活动内容" prop="activity">
        <el-input
            type="textarea"
            :autosize="{ minRows: 2}"
            v-model="form.activity"
            size="small"
            style="width: 25%"
            placeholder="请输入活动内容"
        />
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="活动商品" prop="">
        <el-table v-loading="listLoading" :data="s_list" size="small" style="width: 70%"
                  @selection-change="setSelectRows">
          <el-table-column align="center" show-overflow-tooltip type="selection"/>
          <el-table-column
              align="center"
              label="商品图片"
              prop="imgUrl"
          >
            <template #default="{row}">
              <el-image v-if="row.imgUrl" :src="row.imgUrl" class="commodity_pic"/>
              <span v-else>暂无图片</span>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              label="商品名称"
              prop="name"
          />
          <el-table-column
              align="center"
              label="商品价格"
              prop="good_price"
          />
          <el-table-column
              align="center"
              label="库存"
              prop="stock"
          />
          <el-table-column
              align="center"
              label="活动价格(元)"
              prop="price"
          >
            <template slot-scope="scope">
              <el-input type="number" v-if="scope.row.price" v-model="s_list[scope.$index].price"
                        placeholder="请输入内容" :value="scope.row.price"/>
              <el-input type="number" v-else v-model="s_list[scope.$index].price" placeholder="请输入内容"
                        :value="0"/>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" width="100">
            <template #default="{ row }">
              <el-button type="text" @click="handleDelete(row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-button icon="el-icon-plus" type="primary" @click="showPop = true" size="small" style="margin-top: 10px">
          选择商品
        </el-button>
        <el-button v-if="s_list.length > 0" icon="el-icon-delete" size="small" type="danger" style="margin-top: 10px"
                   @click="handleDelete">批量删除
        </el-button>
        <el-button v-else icon="el-icon-delete" type="danger" size="small" style="margin-top: 10px" disabled>批量删除
        </el-button>
        <PopView :visible.sync="showPop" :chooseGoods="chooseGoods" title="选择商品" @confirm="showPopSubmit"></PopView>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submit" size="small">保存</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>

import PopView from "@/views/components/popView";
import fileSelect from "@/views/components/fileSelect";
import {apiGetProjectInfo, apiOperationProject} from "@/request/api"
import {formatDate} from "@/utils/theTimeStamp";

export default {
  components: {PopView, fileSelect},
  props: {
    chooseGoods: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showSelect: false,
      list: [],
      s_list: [],
      showPop: false,
      query_time: [],
      listLoading: false,
      selectRows: '',
      form: {
        id: '',
        name: '',
        begin_time: '',
        end_time: '',
        mask: '',
        slogan: '',
        activity: '',
        goods: [],
      },
      rules: {
        name: [
          {required: true, trigger: 'blur', message: '活动名不能为空'},
        ],
        mask: [
          {required: true, trigger: 'blur', message: '主图不能为空'},
        ],
        slogan: [
          {required: true, trigger: 'blur', message: '宣传语不能为空'},
        ],
        activity: [
          {required: true, trigger: 'blur', message: '活动介绍不能为空'},
        ],
      },
    }
  },
  created() {
    if (this.$route.query.id) {
      this.getProjectInfo()
    }
  },
  methods: {
    setSelectRows(val) {
      this.selectRows = val
    },
    getProjectInfo() {
      apiGetProjectInfo({id: this.$route.query.id}).then(res => {
        if (res.code == 200) {
          const tempObj = res.data.specialActivity
          const tempObj1 = res.data.specialActivityGoods
          this.form = {
            id: tempObj.id,
            name: tempObj.name,
            begin_time: this.formatDate(tempObj.begin_time),
            end_time: this.formatDate(tempObj.end_time),
            mask: tempObj.mask,
            slogan: tempObj.slogan,
            activity: tempObj.activity,
            goods: this.getGoods(tempObj1),
          }
          this.query_time = [this.formatDate(tempObj.begin_time), this.formatDate(tempObj.end_time),]
        }
      })
    },
    formatDate(time) {
      time = time * 1000
      let date = new Date(time)
      // console.log(new Date(time))
      return formatDate(date, 'yyyy-MM-dd hh:mm:ss')
    },
    getGoods(arr) {
      this.list = []
      if (arr.length > 0) {
        arr.forEach(item => {
          this.list.push({
            sku_id: item.sku_id,
            price: item.price,
          })
          this.s_list.push({
            sku_id: item.sku_info.skuid,
            imgUrl: item.sku_info.imgUrl,
            name: item.sku_info.name,
            good_price: item.sku_info.price,
            stock: item.sku_info.stock,
            price: item.price,
          })
        })
        return this.list
      }
    },
    //选择商品
    showPopSubmit(e) {
      this.showPop = true;
      console.log(e)
      if (e) {
        e.forEach(item => {
          this.s_list.push({
            sku_id: item.skuid,
            imgUrl: item.imgUrl,//商品图片
            name: item.name,//商品名
            good_price: item.price,//价格
            stock: item.stock,//库存
            price: item.price,//团购价
          })
        })
        const smap = new Map()
        // 对id属性进行去重
        this.s_list = this.s_list.filter(key => !smap.has(key.sku_id) && smap.set(key.sku_id, 1))
        this.listLoading = false
      }
    },
    //删除选中商品
    handleDelete(row) {
      if (row.sku_id) {
        this.$confirm('你确定要删除当前项吗', null, {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.s_list.map((val, i) => {
            if (val.sku_id == row.sku_id) {
              this.s_list.splice(i, 1)
            }
          })
        })
      } else {
        this.$confirm('你确定要删除所有选中项吗', null, {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          for (var j = 0; j < this.selectRows.length; j++) {
            this.s_list.map((val, i) => {
              if (val.sku_id == this.selectRows[j].sku_id) {
                this.s_list.splice(i, 1)
              }
            })
          }
        })
      }
    },
    // 活动时间
    getTimeSection(event) {
      if (event.length == 2) {
        this.form.begin_time = event[0]
        this.form.end_time = event[1]
      } else {
        this.form.begin_time = ''
        this.form.end_time = ''
      }
    },
    //上传图片
    confirmHandle(e) {
      console.log(e)
      if (e[0].thumb) {
        this.form.mask = e[0].thumb
      }
      this.showSelect = false
    },
    close() {
      this.$refs['form'].resetFields()
      this.form = this.$options.data().form
      this.query_time = []
      this.s_list = []
    },
    submit() {
      this.list = []
      if (this.form.begin_time == '') {
        this.$message.error('活动时间不能为空')
        return
      }
      if (this.s_list.length > 0) {
        this.s_list.forEach(item => {
          this.list.push({
            sku_id: item.sku_id,
            price: item.price,
          })
        })
        const map = new Map()
        this.form.goods = this.list.filter(key => !map.has(key.sku_id) && map.set(key.sku_id, 1))
      } else {
        this.$message.error('活动商品不能为空')
        return
      }
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          apiOperationProject(this.form).then(res => {
            if (res.code == 200) {
              this.$message.success(res.message)
              this.close()
            } else {
              this.$message.error(res.message)
            }
          })
        } else {
          return false
        }
      })
    },
  }
}

</script>
